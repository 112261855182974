import {
  ABOUT_ME_SECTION_ID,
  COMPACT_LIST_GALLERY,
  COMPACT_MASONRY_GALLERY,
  DEALS_SECTION_ID,
  DESCRIPTION_SECTION_ID,
  FAQ_AWARDS_PREFERRED_SUPPLIERS_SECTION_ID,
  FEATURES_SECTION_ID,
  LOCATION_SECTION_ID,
  PRICING_SECTION_ID,
  REAL_WEDDINGS_SECTION_ID,
  REVIEWS_SECTION_ID,
} from 'app-shared/components/supplier/sections/ids';

export const ANALYTICS_BY_SECTION_ID = {
  [DEALS_SECTION_ID]: 'Deals',
  [ABOUT_ME_SECTION_ID]: 'About',
  [PRICING_SECTION_ID]: 'Pricing',
  [FEATURES_SECTION_ID]: 'Overview',
  [DESCRIPTION_SECTION_ID]: 'Description',
  [REAL_WEDDINGS_SECTION_ID]: 'Real Weddings',
  [REVIEWS_SECTION_ID]: 'Reviews',
  [LOCATION_SECTION_ID]: 'Location',
  [FAQ_AWARDS_PREFERRED_SUPPLIERS_SECTION_ID]: 'FAQs, Awards and Preferred Suppliers',
  [COMPACT_MASONRY_GALLERY]: 'masonryGallery',
  [COMPACT_LIST_GALLERY]: 'listGallery',
} as const;
