import { PartialRecursive } from '@bridebook/models/source/abstract/_';
import { IDatePicker } from '@bridebook/models/source/models/Weddings.types';

interface ExactDate {
  d: number;
  m: number;
  y: number;
}

const isDateExact = (
  date: IDatePicker | PartialRecursive<IDatePicker> | null | undefined,
): date is ExactDate => Object.values(date || {}).every((value) => typeof value === 'number');

export default isDateExact;
