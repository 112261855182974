import { values } from 'ramda';
import { useCallback } from 'react';
import { Weddings } from '@bridebook/models';
import type { IWeddingEvent } from '@bridebook/models/source/models/Weddings/Events.types';
import { toMillis } from '@bridebook/toolbox/src/time-utils/time-utils';
import { type QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'lib/utils';
import { getWeddingId } from 'lib/weddings/selectors';

export const EVENTS_QUERY_CACHE_TIME = toMillis(15, 'min');
export const WEDDING_EVENTS_ROOT_QUERY_KEY = 'wedding-events';

// TODO implement realtime update
export const useWeddingEvents = (mutationFn?: (events: IWeddingEvent[]) => IWeddingEvent[]) => {
  const weddingId = useSelector(getWeddingId);
  const { data, isLoading, isError, error } = useQuery<IWeddingEvent[]>(
    [WEDDING_EVENTS_ROOT_QUERY_KEY, 'list', weddingId],
    async () => {
      const eventsById = await Weddings._.getById(weddingId).Events.query().get();
      return values(eventsById);
    },
    {
      enabled: !!weddingId,
      cacheTime: EVENTS_QUERY_CACHE_TIME,
      staleTime: EVENTS_QUERY_CACHE_TIME,
      select: mutationFn,
    },
  );

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

export const useInvalidateWeddingEvents = () => {
  const queryClient = useQueryClient();
  const invalidate = useCallback(
    async (weddingId: string) => {
      if (weddingId) {
        await queryClient.invalidateQueries([WEDDING_EVENTS_ROOT_QUERY_KEY, 'list', weddingId]);
      }
    },
    [queryClient],
  );

  return { invalidate };
};

export const updateWeddingEventsCache = (
  queryClient: QueryClient,
  weddingId: string,
  events: IWeddingEvent[],
) => {
  queryClient.setQueryData<IWeddingEvent[]>(
    [WEDDING_EVENTS_ROOT_QUERY_KEY, 'list', weddingId],
    events,
  );
};

export const invalidateEventsQuery = (queryClient: QueryClient, weddingId: string) => {
  queryClient.invalidateQueries([WEDDING_EVENTS_ROOT_QUERY_KEY, 'list', weddingId]);
};
