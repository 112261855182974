import Router from 'next/router';
import qs from 'query-string';
import { omit, pick } from 'ramda';
import gazetteer, { Market } from '@bridebook/toolbox/src/gazetteer';
import { Browser } from '@capacitor/browser';
import { env } from 'lib/env';
import { ALLOWED_CUSTOM_PARAMS } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { toUrlQuery } from 'lib/utils/url';

const resolveUrl = async (url: string) => {
  /** We don't need to resolve internal urls */
  if (isInternalUrl(url)) {
    return url;
  }

  try {
    const response = await fetch(`/api/resolve-link-tracking`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        trackUrl: url,
      }),
    });
    if (response.status !== 200) {
      return url;
    }

    const { resolvedUrl } = await response.json();
    return resolvedUrl;
  } catch (error) {
    return url;
  }
};

const isInternalUrl = (url: string, pathname?: string): boolean => {
  const PROXIES_AMOUNT = 5;
  const proxiesUrls = Array.from({ length: PROXIES_AMOUNT }, (_, i) => i + 1).map(
    (val) => `//app-proxy-dev-${val}.bridebook.com`,
  );
  const hosts = [
    ...proxiesUrls,
    '//bb-web-staging.vercel.app',
    '//app-proxy-dev.bridebook.com',
    '//app-proxy-prod.bridebook.co.uk',
    '//app.bridebook.com',
    '//bridebook.co.uk',
    '//bridebook.com',
  ].concat(env.LIVE ? [] : ['vercel.app', '192.168.1', 'ngrok', 'trycloudflare.com']);

  const paths: string[] = [
    UrlHelper.collaboration,
    UrlHelper.guestlist,
    UrlHelper.checklist.main,
    UrlHelper.article.main,
    UrlHelper.shortlist.main,
    UrlHelper.budget.main,
    UrlHelper.home,
    UrlHelper.advice,
    UrlHelper.scrapbook,
    UrlHelper.search,
    UrlHelper.recommendations,
    UrlHelper.settings.main,
    UrlHelper.planning,
    UrlHelper.inbox.main,
    UrlHelper.website.main,
    '/wedding-',
  ];

  if (url.indexOf('http') < 0) return false;
  const marketPrefix = `/${Router.query.market}`;
  return (
    new RegExp(hosts.join('|')).test(url) &&
    (!pathname ||
      pathname === '/' ||
      pathname === marketPrefix ||
      new RegExp(paths.join('|')).test(pathname) ||
      new RegExp(paths.map((path) => `${marketPrefix}${path}`).join('|')).test(pathname))
  );
};

const pathsWithoutLocaleCheck = ['/accept-collaboration-invite'];

export const universalLinksRedirect = async (originalUrl: string) => {
  if (originalUrl.startsWith('bridebook')) {
    originalUrl = originalUrl.replace(
      /^bridebook:\//,
      window.location.origin || 'https://bridebook.com',
    );
  }

  let urlMarket: Market = {} as Market;

  try {
    urlMarket = gazetteer.getMarketByURL(originalUrl);
  } catch (e) {
    /* empty - it's fine if there is no urlMarket returned by gazetteer, means the url has no market */
  }

  const resolvedUrl = await resolveUrl(originalUrl);
  const url = new URL(resolvedUrl);
  const parsedQuery = qs.parse(url.search);
  const queryString = toUrlQuery(omit(['next'], pick(ALLOWED_CUSTOM_PARAMS, parsedQuery)), false);
  const pathname = `${parsedQuery.next ? '/' : ''}${
    parsedQuery.next || url.pathname
  }${queryString}`;

  if (isInternalUrl(resolvedUrl, pathname)) {
    const shouldCheckMarket = !pathsWithoutLocaleCheck.find((path) => pathname.includes(path));

    /**
     * Do not rewrite when:
     * - pathname is empty or it's the root path
     * - pathname includes the resolved url pathname
     * - the market prefix exists and is different than the one in the query and the path is not allowed (is not part of pathsWithoutLocaleCheck array)
     */
    if (
      pathname.length <= 1 ||
      window.location.pathname.includes(url.pathname) ||
      (typeof urlMarket.prefix !== 'undefined' &&
        Router.query.market !== urlMarket.prefix &&
        shouldCheckMarket)
    ) {
      // No need to continue, return early
      return;
    }

    return Router.push(pathname, undefined, { locale: false });
  }

  /**
   * @android
   * This has potential for an endless loop, if we open the app
   * with a deeplink which is not acknowledged as "internal".
   *
   * See this comment for further explanation:
   * https://bridebook.atlassian.net/browse/LIVE-18150?focusedCommentId=67657
   */
  Browser.open({ url: resolvedUrl });
};
