import { format } from 'date-fns';
import { serverTimestamp } from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { type Observable, from, of } from 'rxjs';
import {
  auditTime,
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { Events } from '@bridebook/models/source/models/Weddings/Events';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { env } from 'lib/env';
import { createMainEvent } from 'lib/events/utils';
import { ShortlistActionTypes } from 'lib/shortlist/action-types';
import { getWeddingVenue } from 'lib/shortlist/selectors';
import {
  dateFromIDatePicker,
  getIsoIntervalStartEnd,
  parseIsoInterval,
} from 'lib/time-date-manipulation/time-date-manipulation';
import type { Action, IApplicationState, IDeps, IEpic, IEpicDeps } from 'lib/types';
import { noopAction } from 'lib/utils';
import { invalidateEventsQuery } from 'lib/wedding-website/hooks/query/events/use-events';
import { appError } from '../../app/actions';
import {
  type IFetchCountryBoundsStartAction,
  type IUpdateWeddingFieldAction,
  type IUpdateWeddingPreferencesAction,
  type IWeddingProfileSaveDateAction,
  WeddingActionTypes,
} from '../action-types';
import { fetchCountryBoundsSuccess, pbStatusAnalytics, updateWeddingField } from '../actions';
import { getVenueBooked, getWeddingProfileId } from '../selectors';
import type { ILatLongBounds } from '../types';
import isDateExact from '../utils/is-date-exact';

const saveDate = async (
  datePickerId: 'weddingDate' | 'engagementDate',
  getState: IDeps['getState'],
) => {
  const {
    datepicker: { instances },
  } = getState();

  const datePickerDate = instances[datePickerId].datePickerDate;

  return { datePickerDate };
};

export const weddingProfileSaveDateEpics = (
  action$: Observable<IWeddingProfileSaveDateAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(WeddingActionTypes.SAVE_WEDDING_DATE),
    auditTime(300),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { datePickerId },
        },
        state,
      ]) =>
        from(saveDate(datePickerId, () => state)).pipe(
          map(() => ({
            type: WeddingActionTypes.SAVE_WEDDING_DATE_SUCCESS,
            // for analytics
            payload: { name: datePickerId },
          })),
          catchError((error) =>
            of(appError({ error, feature: 'Wedding' }), {
              type: WeddingActionTypes.SAVE_WEDDING_DATE_ERROR,
              payload: error,
            }),
          ),
        ),
    ),
  );

/**
 * Function `updateBookingsVenueEpic`
 * Listens to shortlist actions in order to
 * update bookings.venue (previously 'venueBooked')
 *
 * @function updateBookingsVenueEpic
 * @param {action$, IDeps}
 */
export const updateBookingsVenueEpic: IEpic = (action$, { state$ }) =>
  action$.pipe(
    ofType(
      ShortlistActionTypes.ON_SUPPLIERS_SHORTLISTED_SUCCESS,
      ShortlistActionTypes.ON_REMOVED_SUPPLIERS_FROM_SHORTLIST,
    ),
    auditTime(250),
    withLatestFrom(state$),
    mergeMap(([, state]: [Action, IApplicationState]) => {
      const {
        app: { pathname },
      } = state;

      //We want to check if the shortlist is loaded to prevent changing the bookings value
      //when ON_REMOVED_SUPPLIERS_FROM_SHORTLIST or ON_SUPPLIERS_SHORTLISTED_SUCCESS
      //is emitted first and the state is not ready yet
      if (!state.shortlist.loaded) {
        return of(noopAction());
      }

      const weddingVenue = getWeddingVenue(state);
      const venueBooked = getVenueBooked(state);
      const isOnboarding = pathname.startsWith('/onboarding/venue');
      const actions: Action[] = [];
      if ((weddingVenue && !venueBooked) || isOnboarding) {
        actions.push(updateWeddingField('bookings', { bookings: { venue: true } }));
        actions.push(pbStatusAnalytics(false));
      } else if (!weddingVenue && venueBooked) {
        actions.push(updateWeddingField('bookings', { bookings: { venue: false } }));
        actions.push(pbStatusAnalytics(true));
      }

      return of(...actions);
    }),
  );

export const updateWeddingFieldEpic = (
  action$: Observable<IUpdateWeddingFieldAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(WeddingActionTypes.UPDATE_WEDDING_FIELD),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { name, value, extraAnalytics },
        },
        state,
      ]) => {
        const {
          weddings: { profile },
        } = state;

        if (!profile?.id) {
          return of();
        }

        return from(
          Weddings._.getById(profile.id).set(value, name === 'location' ? [name] : true),
        ).pipe(
          mergeMap(() =>
            of({
              type: WeddingActionTypes.UPDATE_WEDDING_FIELD_SUCCESS,
              payload: { name, value, extraAnalytics },
            }),
          ),
          catchError((error) =>
            of(appError({ error, feature: 'Wedding' }), {
              type: WeddingActionTypes.UPDATE_WEDDING_FIELD_ERROR,
              payload: { name, value, error },
            }),
          ),
        );
      },
    ),
  );

export const fetchCountryBoundsEpic = (action$: Observable<IFetchCountryBoundsStartAction>) =>
  action$.pipe(
    ofType(WeddingActionTypes.FETCH_PROFILE_COUNTRY_BOUNDS_START),
    mergeMap(({ payload }) => {
      // Temporary exception to show autocomplete suggestions for Illinois
      // @see https://bridebook.atlassian.net/browse/LIVE-12910
      const countryCode = payload === CountryCodes.US ? 'US_Illinois' : payload;

      const promise = fetch(
        `${env.STATIC}/static/country-lat-long-bounds/${countryCode}.json`,
      ).then((data) => data.json() as Promise<ILatLongBounds>);

      return from(promise).pipe(
        map((countryLatLongBounds) => fetchCountryBoundsSuccess(countryLatLongBounds)),
        catchError((error) =>
          of(appError({ error, feature: 'Wedding' }), {
            type: WeddingActionTypes.FETCH_PROFILE_COUNTRY_BOUNDS_ERROR,
            payload: error,
          }),
        ),
      );
    }),
  );

export const updateWeddingPreferencesEpic = (
  action$: Observable<IUpdateWeddingPreferencesAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(WeddingActionTypes.UPDATE_WEDDING_PREFERENCES),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const getPromise = async () => {
        const { id: preferenceId, property, value } = payload;
        const weddingId = getWeddingProfileId(state);
        const wedding = Weddings._.getById(weddingId);
        const preferences = await wedding.Preferences.getById(preferenceId).get();
        if (preferences) {
          wedding.Preferences.getById(preferenceId).set({ [property]: value });
        } else {
          wedding.Preferences.getById(preferenceId).set({
            createdAt: serverTimestamp(),
            id: preferenceId,
            [property]: value,
          });
        }
      };

      return from(getPromise()).pipe(
        mergeMap(() => of()),
        catchError((error: Error) =>
          of(appError({ error, feature: 'Updating wedding preferences' })),
        ),
      );
    }),
  );

// this epic is used to sync the Wedding updates with the Main Event
// 1. it updates the Date whenever it is changed
// 2. it creates the Main Event if it doesn't exist (only if venue is set)
// Date selection is the last step of MAB flow, so with the checks it works quite nice for this Wedding - Main Event sync.
export const syncWeddingWithMainEventEpic = (
  action$: Observable<IUpdateWeddingFieldAction>,
  { state$, queryClient }: IEpicDeps,
) =>
  action$.pipe(
    ofType(WeddingActionTypes.UPDATE_WEDDING_FIELD_SUCCESS),
    withLatestFrom(state$),
    filter(([action]) => action.payload.name === 'date'),
    tap(async ([action, state]) => {
      if (isDateExact(action.payload.value.date)) {
        const date = dateFromIDatePicker(action.payload.value.date);
        if (!date) return;
        const weddingId = state.weddings.profile?.id;

        const mainEventRef = await Weddings._.getById(weddingId).Events.getById(Events.MAIN_EVENT);
        const mainEvent = await mainEventRef.get();

        if (mainEvent) {
          // If Main Event exists, only updating the date
          // we also need the time from the existing main event if it is set
          const mainEventStartTime = format(parseIsoInterval(mainEvent.date).start, 'HH:mm');
          const mainEventEndTime = format(parseIsoInterval(mainEvent.date).end, 'HH:mm');
          const dateToSet = getIsoIntervalStartEnd(date, mainEventStartTime, mainEventEndTime);
          await mainEventRef.set({ date: dateToSet }, true);
          invalidateEventsQuery(queryClient, weddingId);
        } else {
          const weddingVenue = getWeddingVenue(state);
          if (weddingVenue) {
            // If the Main Event doesn't exist and the wedding venue is set, create the Main Event
            await createMainEvent({ weddingId, date, queryClient });
          }
        }
      }
    }),
    ignoreElements(),
  );
