import { format as _format, differenceInMilliseconds, parse, parseISO } from 'date-fns';
import { IDatePicker } from '@bridebook/models/source/models/Weddings.types';
import { getDateFnsLocale } from 'lib/i18n/utils/get-date-fns-format';
import isDateExact from 'lib/weddings/utils/is-date-exact';

/**
 * The date/time formats below use date-fns special format tokens P and p for locale-aware formatting:
 * Formats that use P and p will automatically adjust to match the user's locale settings.
 *
 * @example <caption>Time format token 'p'</caption>
 * // US locale: "12:00 AM"
 * // UK locale: "00:00"
 *
 * @example <caption>Date format token 'P'</caption>
 * // US locale: "04/29/2023"
 * // UK locale: "29/04/2023"
 *
 */
export const DATE_FORMAT = {
  TIME: {
    SHORT: 'p', // 13:00 or 1:00 PM (locale dependent)
    WITH_SECONDS: 'pp', // 13:00:00 or 1:00:00 PM
  },
  DATE: {
    SHORT: 'P', // 21/09/2025 or 09/21/2025
    MEDIUM: 'd MMM yyyy', // 21 Sep 2025
    LONG: 'd MMMM yyyy', // 21 September 2025
    WITH_DAY: 'EEEE, d MMMM yyyy', // Sunday, 21 September 2025
    WITH_FULL_DAY_SHORT_MONTH: 'EEEE, d MMM yyyy', // Sunday, 21 Sep 2025
    WITH_SHORT_DAY: 'EEE, P', // Sun, 21/09/2025
    WITH_SHORT_DAY_SHORT_MONTH_YEAR: 'EEE d MMM yyyy', // Sun, 21 Sep 2025
    CALENDAR: {
      DATE: 'do MMM', // 21st Sep
      FULL: 'EEE, do MMM', // Sun, 21st Sep
    },
  },
  DATETIME: {
    SHORT: 'Pp', // 21/09/2025, 13:00
    MEDIUM: 'EEE, PPp', // Sun, 21 Sep 2025, 13:00
    LONG: 'EEEE, PPp', // Sunday, 21 Sep 2025, 13:00
    WITH_DAY: 'EEE do MMM, p', // Sun 21st Sep, 13:00
    TIME_FIRST: {
      SHORT: 'p, P', // 13:00, 21/09/2025
      MEDIUM: 'p, d MMM yyyy', // 13:00, 21 Sep 2025
      LONG: 'p, d MMMM yyyy', // 13:00, 21 September 2025
      WITH_DAY: 'p, EEE do MMM', // 13:00, Sun 21st Sep
    },
  },
  MONTH_YEAR: 'MMMM yyyy', // September 2025
};

// Below functions are used for Events (Wedding Website)
// WE ARE USING THIS FORMAT:
// ISO 8601 interval
// 2025-05-21T20:00:00.000Z/2025-05-21T23:00:00.000Z
// 2025-05-21T20:00:00.000+08:00/2025-05-21T23:00:00.000+08:00
export function parseIsoInterval(startEndString: string) {
  const [start, end] = startEndString.split('/');
  const startDate = parseISO(start);
  const endDate = parseISO(end);
  const duration = differenceInMilliseconds(endDate, startDate);
  return {
    start: startDate,
    end: endDate,
    duration,
  };
}

export function format(
  date: Date,
  formatStr: string,
  options: Parameters<typeof _format>[2] = {},
): string {
  const locale = getDateFnsLocale();

  return _format(date, formatStr, {
    locale,
    ...options,
  });
}

export function getIsoIntervalStartEnd(date: Date, startTime: string, endTime: string): string {
  const dateStr = format(date, 'yyyy-MM-dd');
  const startDateTime = parse(`${dateStr} ${startTime}`, 'yyyy-MM-dd HH:mm', new Date());
  const endDateTime = parse(`${dateStr} ${endTime}`, 'yyyy-MM-dd HH:mm', new Date());

  return `${startDateTime.toISOString()}/${endDateTime.toISOString()}`;
}

export function dateFromIDatePicker(date: IDatePicker): Date | null {
  if (isDateExact(date)) {
    return parse(`${date.y}-${date.m + 1}-${date.d}`, 'yyyy-MM-dd', new Date());
  }
  return null;
}
