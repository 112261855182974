import { keyBy } from 'lodash';
import type { IUISupplier, Slug } from '@bridebook/toolbox/src/types';
import { IWeddingKeyword } from 'components/keywords/types';
import { IntentType } from 'components/onboarding-new/components/steps/couple-intent';
import { Chapter } from 'lib/onboarding-new/chapters/Chapter';
import { StepId } from 'lib/onboarding-new/step-id';
import { Step } from 'lib/onboarding-new/types';

export enum NewOnboardingAnalyticsAction {
  StartedUserOnboarding = 'Started user onboarding',
  FinishedUserOnboarding = 'Finished user onboarding',
  CompletedUserOnboardingStep = 'Completed user onboarding step',
  SkippedUserOnboardingStep = 'Skipped user onboarding step',
  EditedWeddingDetails = 'Edited wedding details',
  ClickedBackOnUserOnboardingStep = 'Clicked back on user onboarding step',
  LoadedSearchResults = 'Loaded search results',
  TriggeredWeddingInsurancePopup = 'Triggered wedding insurance popup',
  UserSelectedNextSupplierCategory = 'User selected next supplier category',
  UserChoseQuizFilter = 'User chose quiz filters',
  SelectedWeddingKeyword = 'Selected wedding keyword',
  ConfirmedWeddingKeyword = 'Confirmed wedding keywords',
  ClosedConciergeMatchesScreen = 'Closed concierge matches screen',
  SelectPlaningStage = 'Selected planning stage',
}

const skippedUserOnboardingStep = (payload: { currentStep: Step; currentChapter: Chapter }) => ({
  type: NewOnboardingAnalyticsAction.SkippedUserOnboardingStep,
  payload,
});

const clickedBackOnUserOnboardingStep = (payload: {
  currentStep: Step;
  currentChapter: Chapter;
}) => ({ type: NewOnboardingAnalyticsAction.ClickedBackOnUserOnboardingStep, payload });

const completedUserOnboardingStep = (payload: { currentStep: Step; currentChapter: Chapter }) => ({
  type: NewOnboardingAnalyticsAction.CompletedUserOnboardingStep,
  payload,
});

const selectedPlanningStageStep = (payload: { selectedPlanningStage: IntentType }) => ({
  type: NewOnboardingAnalyticsAction.SelectPlaningStage,
  payload,
});

const loadedSearchResults = (payload: {
  searchActiveFilters: Record<string, any>;
  searchSortedBy: string;
  searchResultsTotal: number;
  list: IUISupplier[];
  searchSupplierCategory?: Slug;
  userId?: string;
  searchTerm?: string;
}) => ({
  type: NewOnboardingAnalyticsAction.LoadedSearchResults,
  payload,
});

const startedUserOnboarding = () => ({
  type: NewOnboardingAnalyticsAction.StartedUserOnboarding,
});

const triggeredWeddingInsurancePopup = () => ({
  type: NewOnboardingAnalyticsAction.TriggeredWeddingInsurancePopup,
});

const userSelectedNextSupplierCategory = (payload: {
  nextSupplierCategory?: Slug;
  nextSupplierSelectedLocation: string;
}) => ({
  type: NewOnboardingAnalyticsAction.UserSelectedNextSupplierCategory,
  payload,
});

const userChoseQuizFilter = (payload: {
  quizName: 'onboardingVenueQuiz' | 'onboardingPhotographerQuiz';
  quizStepContent: StepId;
  quizStepNumber: number;
  quizFilters: Record<string, any>;
}) => ({
  type: NewOnboardingAnalyticsAction.UserChoseQuizFilter,
  payload,
});

const selectedWeddingKeyword = (payload: {
  selectedWeddingKeyword: string;
  selectedWeddingKeywordCategory: string;
  selectedWeddingKeywordId: string;
  weddingKeywordsLocation?: string;
}) => ({
  type: NewOnboardingAnalyticsAction.SelectedWeddingKeyword,
  payload: {
    ...payload,
    weddingKeywordsLocation: payload.weddingKeywordsLocation ?? 'onboarding',
    category: 'settings',
  },
});

const confirmedWeddingKeyword = (payload: {
  selectedWeddingKeywords: IWeddingKeyword[];
  weddingKeywordsLocation?: string;
}) => {
  const selectedKeywordsById = keyBy(
    payload.selectedWeddingKeywords.map((item) => ({
      id: item.id,
      keyword: item.keyword,
      category: item.category,
    })),
    'id',
  );
  return {
    type: NewOnboardingAnalyticsAction.ConfirmedWeddingKeyword,
    payload: {
      ...payload,
      selectedWeddingKeywords: selectedKeywordsById,
      weddingKeywordsCount: payload.selectedWeddingKeywords.length,
      weddingKeywordsLocation: payload.weddingKeywordsLocation ?? 'onboarding',
      category: 'settings',
    },
  };
};

const finishedUserOnboarding = () => ({
  type: NewOnboardingAnalyticsAction.FinishedUserOnboarding,
});

//Closed concierge matches screen
const closedConciergeMatchesScreen = () => ({
  type: NewOnboardingAnalyticsAction.ClosedConciergeMatchesScreen,
});

export const analytics = {
  skippedUserOnboardingStep,
  clickedBackOnUserOnboardingStep,
  completedUserOnboardingStep,
  selectedPlanningStageStep,
  loadedSearchResults,
  startedUserOnboarding,
  triggeredWeddingInsurancePopup,
  userSelectedNextSupplierCategory,
  userChoseQuizFilter,
  finishedUserOnboarding,
  selectedWeddingKeyword,
  confirmedWeddingKeyword,
  closedConciergeMatchesScreen,
};
