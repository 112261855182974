import {
  IWeddingEvent,
  IWeddingEvent2,
} from '@bridebook/models/source/models/Weddings/Events.types';

export enum EventType {
  MAIN_EVENT = 'MainEvent',
  CUSTOM_EVENT = 'CustomEvent',
}

export type WeddingCustomEvent = Extract<IWeddingEvent, { type: 'CustomEvent' }>;
export type WeddingMainEvent = Extract<IWeddingEvent, { type: 'MainEvent' }>;

export type WeddingEvent = WeddingCustomEvent | WeddingMainEvent;

export type CommonWeddingEventFields = Omit<IWeddingEvent2, 'createdAt' | 'updatedAt'> &
  Pick<IWeddingEvent, 'id'>;
