import { ofType } from 'redux-observable';
import { type Observable, of } from 'rxjs';
import { filter, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import type { IApplicationState, IEpicDeps } from 'lib/types';
import { updateWeddingWebsiteCache } from 'lib/wedding-website/hooks/query/website/use-wedding-website';
import { updateWedding } from 'lib/weddings/actions';

export const initWeddingListener = (action$: Observable<any>, { state$, queryClient }: IEpicDeps) =>
  action$.pipe(
    ofType('INIT_WEDDING_LISTENER'),
    withLatestFrom(state$),
    filter(([_, state]: [any, IApplicationState]) => Boolean(state.weddings.profile.id)),
    mergeMap(([_, state]: [any, IApplicationState]) => {
      const weddingId = state.weddings.profile.id;

      return Weddings._.getById(weddingId)
        .observe()
        .pipe(
          mergeMap((data) => {
            try {
              if (data) {
                updateWeddingWebsiteCache(queryClient, weddingId, data.website);
              }
            } catch (e) {
              SentryMinimal().captureMessage('Failed to refresh wedding website cache', e);
            }

            return data ? of(updateWedding(data)) : of();
          }),
          takeUntil(action$.pipe(ofType('STOP_WEDDING_LISTENER'))),
        );
    }),
  );
