import { Timestamp, WithFieldValue, serverTimestamp } from 'firebase/firestore';
import uuid from 'uuid-random';
import { Weddings } from '@bridebook/models/source/models/Weddings';
import { Events } from '@bridebook/models/source/models/Weddings/Events';
import { IWeddingEvent } from '@bridebook/models/source/models/Weddings/Events.types';
import { QueryClient } from '@tanstack/react-query';
import { EventType } from 'components/wedding-website/events/types';
import { getI18n } from 'lib/i18n/getI18n';
import { getIsoIntervalStartEnd } from 'lib/time-date-manipulation/time-date-manipulation';
import { invalidateEventsQuery } from 'lib/wedding-website/hooks/query/events/use-events';

export const newMainEvent = ({ date }: { date: string }): WithFieldValue<IWeddingEvent> => ({
  id: Events.MAIN_EVENT,
  type: EventType.MAIN_EVENT,
  name: getI18n().t('weddingWebsite:events.mainEventDefaultName'),
  date,
  description: '',
  attire: '',
  visible: true,
  featured: true,
  createdAt: serverTimestamp(),
  updatedAt: serverTimestamp(),
});

interface ICreateEventOptions {
  weddingId: string;
  date: Date;
  queryClient: QueryClient;
}

export const createMainEvent = async ({ weddingId, date, queryClient }: ICreateEventOptions) => {
  const dateToSet = getIsoIntervalStartEnd(date, '12:00', '12:00');
  await Weddings._.getById(weddingId)
    .Events.push(Events.MAIN_EVENT)
    .create(newMainEvent({ date: dateToSet }));
  invalidateEventsQuery(queryClient, weddingId);
};

export const createMainEventIfNotExists = async ({
  weddingId,
  date,
  queryClient,
}: ICreateEventOptions) => {
  const mainEventRef = await Weddings._.getById(weddingId).Events.getById(Events.MAIN_EVENT);
  const mainEvent = await mainEventRef.get();

  if (!mainEvent) {
    await createMainEvent({ weddingId, date, queryClient });
  }
};

export const getNewCustomWeddingEvent = (): IWeddingEvent => ({
  id: uuid(),
  type: EventType.CUSTOM_EVENT,
  name: '',
  date: '',
  description: '',
  attire: '',
  visible: true,
  featured: true,
  supplier: '',
  createdAt: new Timestamp(0, 0),
});
